import { translate } from '@/core/localization/translate';
export const {
  title,
  subtitle1,
  text1,
  subtitle2,
  text2,
  subtitle3,
  text3
} = translate({
  "title": "Il pi\xF9 grande ecommerce in Europa per il fai da te, la casa e il giardinaggio",
  "subtitle1": "Milioni di prodotti",
  "text1": "Molti di pi\xF9 che in un negozio tradizionale... e senza dover cercare tra le corsie",
  "subtitle2": "Consegna a domicilio o in punto di raccolta",
  "text2": "Scegli tu",
  "subtitle3": "Centinaia di esperti pronti ad aiutarti",
  "text3": "Tipo Macgyver ma online"
});